<template>
    <el-col>
        <el-form inline :model="searchForm" label-width="140px" style="margin-top: 30px;">
            <el-form-item label="关键字">
                <el-input v-model="searchForm.key" autocomplete="off"></el-input>
            </el-form-item>
            <el-button @click="onSearch" type="primary" icon="el-icon-search">搜索</el-button>
        </el-form>

         <el-table :data="userRoleList"  style="width: 100%">       
            <!-- <el-table-column label="供应商名称" prop="companyName"></el-table-column> -->
            <el-table-column label="角色名称" prop="roleName"></el-table-column>
            <el-table-column label="说明" prop="remark"></el-table-column>
            <el-table-column label="维护人" prop="name"></el-table-column>
            <el-table-column label="创建日期" prop="rowTime"></el-table-column>
            <el-table-column label="管理" prop="temp" align="center">
                <template v-slot="scope">
                    <el-button type="text" @click="onItemEdit(scope.row)">编辑</el-button>
                    <el-button type="text" @click="onItemDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <create-role-dialog v-model="editRoleDialogVisible" :form-data="tempItemInfo" :visible="editRoleDialogVisible" @closedialog="closedialog"/>
    </el-col>
</template>

<script>
    import CommonTable from "@/components/CommonTable";
    import CommonTableColumn from "@/components/CommonTableColumn";
    import {mapGetters} from "vuex";
    import CreateRoleDialog from "@/views/System/Role/CreateRoleDialog";
	// import {Message, MessageBox} from "element-ui";

    export default {
        name: "RoleTable",
        components: {CreateRoleDialog, CommonTableColumn, CommonTable},
        computed: {...mapGetters(['userRoleList'])},
        created() {
            this.onSearch();
            
        },
        data() {
            return {
                list: [], 
                editRoleDialogVisible: false, 
                tempItemInfo: {},
                searchForm: {
                    key: "",
                    dateArray: ['2021-01-01','2099-12-31'],//getLatestMonthDateArrayByDate({date:new Date(),delayNum:1, formatValue: 'yyyy-MM-dd'}),
                },
            }
        },
        methods: {
            async onSearch() {
                // console.log(this.searchForm);
                const [sDate, eDate] = this.searchForm.dateArray;
                const {key}=this.searchForm;
                // 
                // console.log(sDate,eDate,this.searchForm.statusId);
                await this.$store.dispatch('user/getUserRoleList',{
                    key,
                }).then(()=>{
                    console.log('userRoleList',this.userRoleList)
                })
                
            },

            async onItemEdit(item) {
                // console.log('item',item);
                this.tempItemInfo = item;
                this.editRoleDialogVisible = true;
            },
            async onItemDelete(item) {
                console.log(item);
								MessageBox.confirm('即将执行删除操作, 是否继续?', '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning'
								}).then(async () => {
										return this.$store.dispatch('user/userRoleDelete', {roleId: item.roleId});
								}).then(async () => {
										Message.success('删除成功!');
										await this.$store.dispatch('user/userRoleList');

								});
            },

            closedialog(){
                // console.log('close',this.editRoleDialogVisible)
                this.editRoleDialogVisible = false;
                
            }
        }
    }
</script>

<style scoped>
/deep/ .el-table thead {
    color:#000;
}
</style>