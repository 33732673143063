<template>
    <div class="system-user-container" v-loading="loading">
        <div class="radius-border" style="height:60px;">
            <el-row type="flex" justify="end"  style="padding-top: 10px;margin-right:20px;">
                <el-button @click="createRoleDialogVisible=true" type="primary" >+新建角色</el-button>
            </el-row>
        </div>

        <create-role-dialog v-model="createRoleDialogVisible"  @closedialog="closedialog" :visible="createRoleDialogVisible" :form-data="{roleName: '', roleId: '', remark: '', checkedArray: []}"/>
        <div class="radius-border-post" >
        <role-table style="width:100%;"/>
        </div>
    </div>
</template>

<script>

    import CreateRoleDialog from "@/views/System/Role/CreateRoleDialog";
    import RoleTable from "@/views/System/Role/RoleTable";

    export default {
        name: "Role",
        components: {RoleTable, CreateRoleDialog},
        computed: {},
        data() {
            return {
                loading: true, 
                createRoleDialogVisible: false,
                
            };
        },
        async created() {
           
        },
        mounted() {
            this.loading = false;
        },
        methods: {
            closedialog(){
                // console.log('close',this.editRoleDialogVisible)
                this.createRoleDialogVisible = false;
                
            }
        }
    }
</script>

<style scoped>

</style>
