<template>
		<el-dialog :title="formData.roleId?'编辑角色':'新建角色'" v-model="visible" :before-close="beforeClose" width="65%">
				<el-form :model="form" label-width="100">
						<el-form-item label="角色名称">
								<el-input v-model="form.roleName" autocomplete="off" style="width: 400px;"></el-input>
						</el-form-item>
						<el-form-item label="说明" style="width: 100%;">
								<el-input
										v-model="form.remark"
										type="textarea"
										:autosize="{ minRows: 2, maxRows: 4}"
										placeholder="说明文字"
										style="width: 400px;">
								</el-input>
						</el-form-item>
						<el-table :data="roleModuleList" border
											ref="permissionTable"
											max-height="350"
											:header-cell-style="{background:'#eef1f6',color:'#606266'}"
											@selection-change="handleSelectionChange">
								<el-table-column
										type="selection"
										align="center"
										width="55">
								</el-table-column>
								<el-table-column label="功能模块" prop="label" align="center" width="180"></el-table-column>
								<el-table-column label="权限" prop="label" align="center">
										<template v-slot="scope">
												<!--												<el-checkbox >添加</el-checkbox>-->
												<div class="flex-row justify-content-center fill-width" style="display:flex;">
														<el-checkbox-group v-model="scope.row.permissionsCheckArray"
																							 @change="handleCheckedCitiesChange($event,scope.row)">
																<el-checkbox v-for="permission in permissions" :label="permission" :key="permission">
																		{{permission}}
																</el-checkbox>
														</el-checkbox-group>
														<el-checkbox :indeterminate="scope.row.isIndeterminate" v-model="scope.row.checkAll"
																				 @change="handleCheckAllChange($event,scope.row);" style="margin-left: 30px;">全选
														</el-checkbox>
												</div>
										</template>

								</el-table-column>
						</el-table>
				</el-form>
				<div style="display:flex;justify-content:center;margin-top:1rem;">
						<el-button @click="beforeClose">取 消</el-button>
						<el-button type="primary" @click="onConfirm">确 定</el-button>
				</div>
		</el-dialog>
</template>

<script>

import {mapGetters} from "vuex";
import {PermissionOptions, userRoleDetail} from "@/api/role";

export default {
		name: "CreateRoleDialog",
		components: {},
		computed: {...mapGetters(['roleModuleList'])},
		props: {
				visible: {
					type: Boolean,
					required: true,
					default:false
				},
				formData: {
					type: Object,
					default() {
						return {roleName: '', roleId: '', remark: ''};
					}
				}
		},
		mounted(){
			// this.visible=false;
		},

		watch: {
			
				async visible(newValue) {		
					console.log('visible')			
					if (newValue) {
						this.form = this.formData;		
						// console.log('this.form',this.form);						
						const {roleId} = this.formData
						if (roleId) {
							const {data} = await userRoleDetail({roleId});
							this.form = data;
							// console.log('ni tamade !!!!', data);
							await this.$store.dispatch('user/UserRoleDetail_AllModule', roleId);
						} else {
							this.form = {roleName: '', roleId: '', remark: '', checkedArray: []}
							await this.$store.dispatch('user/getRoleModuleList');
							// console.log('aa',this.roleModuleList)
						}
					}
				},
				async roleModuleList(newValue) {
					console.log('watch roleModuleList', newValue);
					// console.warn('this.form.checkedArray',this.form.checkedArray);
					await this.$nextTick();
					this.toggleSelection(newValue.filter(item=>item.selected));
				}
		},
		data() {
				return {
						permissions: [...PermissionOptions],
						form: {roleName: '', roleId: '', remark: '', checkedArray: []},
						// chooseModuleDialogVisible: false,
				}
		},
		methods: {
				findTargetPermission(permissionsCheckArray,key) {
						return !!permissionsCheckArray.find(item => item === key);
				},

				async onConfirm() {
						console.log(this.form);
						const {
								roleId,
								roleName,
								remark,
								checkedArray
						} = this.form

						if (!roleName?.trim()) {
							this.$message.warning('请输入角色名称')
							return;
						}
						if (checkedArray.length<1) {
							this.$message.warning('请选择功能模块')
							return;
						}

						await this.$store.dispatch('user/userRoleModify', {
								roleId,
								roleName,
								remark,
								rightArray: JSON.stringify(checkedArray.map(item => {
										const {value:moduleId,label:moduleName,permissionsCheckArray} = item;

										return {
												moduleId,
												moduleName,
												canAdd:this.findTargetPermission(permissionsCheckArray,'添加'),
												canDelete:this.findTargetPermission(permissionsCheckArray,'删除'),
												canModify:this.findTargetPermission(permissionsCheckArray,'修改'),
												canQuery:this.findTargetPermission(permissionsCheckArray,'系数设置'),
												canExport:this.findTargetPermission(permissionsCheckArray,'导出'),
												canUpdown:this.findTargetPermission(permissionsCheckArray,'上下线'),
										}
								}))
						});
						this.$message.success(this.formData.roleId ? '修改成功' : '新建成功');
						await this.$store.dispatch('user/getUserRoleList',{key: '',});
						this.beforeClose();
				},
				beforeClose() {
					// console.log('bbb')
					// this.visible=false;
					this.$emit('closedialog','');
				},
				getSelectedItem(value) {
						return this.roleModuleList.find(item => item.value === value).label;
				},
				handleCheckAllChange(val, item) {
						item.permissionsCheckArray = val ? PermissionOptions : [];
						item.isIndeterminate = false;
				},
				handleCheckedCitiesChange(value, item) {
						let checkedCount = value.length;
						item.checkAll = checkedCount === PermissionOptions.length;
						item.isIndeterminate = checkedCount > 0 && checkedCount < PermissionOptions.length;
				},
				async toggleSelection(rows) {
						await this.$nextTick();
						const permissionTable = this.$refs.permissionTable;
						if (rows) {
								rows.forEach(row => {
										permissionTable && permissionTable.toggleRowSelection(row, true);
								});
						} else {
								permissionTable && permissionTable.clearSelection();
						}
				},
				handleSelectionChange(val) {
						// console.log(val);
						this.form.checkedArray = val;
				}
		}
}
</script>

<style scoped>

</style>