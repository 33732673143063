<template>
    <el-table-column
            :prop="prop"
            align="center"
            :label="label"
						:sortable="sortable"
						:formatter="formatter"
            
            :width="width">
            <slot></slot>
    </el-table-column>
</template>

<script>
    export default {
        name: "CommonTableColumn",
        props: {
            prop: {
                type: String,
                default: '',
                required: true,
            },
            label: {
                type: String,
                default: '',
            },
            width: {
                type: String,
            },
						sortable:{
								type: [String, Boolean],
								default: false
						},
						formatter:{
            		type:Function,
								default(row) {
										const [firstProperty,secondProperty] = this.property.split('.')
										if (!secondProperty) {
												return row[firstProperty] || '--';
										} else {
												return row[firstProperty][secondProperty] || '--';
										}
								}
						}
        }
    }
</script>

<style scoped>

</style>
